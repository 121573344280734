import React from 'react';
import styled from '@emotion/styled';
import Loader from '@components/common/Loader';

import { mixins } from '@styles';

const Cover = styled.div`
  ${mixins.absoluteFill}
  position: fixed;
  background: white;
  z-index: 100;
  opacity: 0;
  visibility: hidden;

  ${mixins.transitionDefault}

  &.active {
    opacity: 0.9;
    visibility: visible;
  }
`;

const StyledLoader = styled(Loader)`
  ${mixins.absoluteCenter}
  ${mixins.transitionDefault}
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
  transition-delay: 300ms;

  .active > & {
    transform: translate(-50%, -50%) scale(1);
  }
`;

interface Props {
  active: boolean;
}

export default (props: Props) => (
  <Cover className={props.active ? 'active' : ''}>
    <StyledLoader />
  </Cover>
);
