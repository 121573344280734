import React from 'react';
import Page from '@components/layout/Page';
import Intercars from '@components/interCars';
import MetaPage from '@components/common/MetaPage';
import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';
import useIntercarsPageQuery from '@graphql/queries/IntercarsQuery';
import { PageContext } from '@content/types/pageContext';

interface IntercarsProps extends LocationDependent {
  pageContext: PageContext;
}

const DemoPage = (props: IntercarsProps) => {
  const intercarsData = useIntercarsPageQuery()[props.pageContext.langcode];

  return (
    <Page>
      {intercarsData && (
        <MetaPage title={intercarsData.seo.title} description={intercarsData.seo.description} />
      )}
      <DataLayer location={props.location} />
      <Intercars {...intercarsData} langcode={props.pageContext.langcode} />
    </Page>
  );
};

export default DemoPage;
