import React, { Component } from 'react';
import * as utils from '@utils';
import { LocationDependent } from '@content/types/general';

interface DataLayerProps extends LocationDependent {
  transactionId?: string | null;
  transactionTotal?: string | number | null;
  userId?: string | null;
}

class DataLayer extends Component<DataLayerProps> {
  componentDidMount() {
    const { location, transactionId = null, transactionTotal = null, userId = null } = this.props;

    utils.setDatalayer(location, transactionId, transactionTotal, userId);
  }

  render() {
    return null;
  }
}

export default DataLayer;
