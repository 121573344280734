import React, { Component } from 'react';
import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import { LocationDependent } from '@content/types/general';
import { IntercarsPageProps } from '@content/types/intercars';

import InterCarsTitle from '@components/interCars/InterCarsTitle';
import InterCarsGrid from '@components/interCars/InterCarsGrid';

export interface IntercarsProps extends LocationDependent {
  title: string;
  langcode: string;
}

class InterCarsPage extends Component<IntercarsProps> {
  render() {
    return (
      <Page>
        <Container>
          <InterCarsTitle title={this.props.title} />
          <InterCarsGrid data={this.props} />
        </Container>
      </Page>
    );
  }
}

export default InterCarsPage;
