import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { Location, LocationContext } from '@reach/router';

interface Props {
  title?: string;
  description?: string;
  className?: string;
  image?: string;
}

const MetaPage: FC<Props> = ({ title, description, image }) => {
    const encodeImage = (imageUrl) => {
        const parts = imageUrl.split('/');
        return parts.map(part => encodeURIComponent(part)).join('/');
    };

  return (
    <>
      <Location>
        {({ location, navigate }: LocationContext) => {
          const t =
            title !== null
              ? `${title}${
                  title && title.indexOf('iPOS') !== -1 ? '' : `${title ? ' — ' : ''}iPOS`
                }`
              : 'iPOS';

          return (
            <Helmet defer={false} defaultTitle={'iPOS'}>
              <title>{t}</title>
              <meta name="title" content={t} />
              {!!description && <meta name="description" content={description} />}

              {/* <!-- Open Graph / Facebook --> */}
              <meta property="og:type" content="website" />
              <meta property="og:locale" content="pl" />
              {/* <meta property="og:url" content={`${location.href}`} /> */}
              {/* TODO: page url */}
              <meta property="og:title" content={t} />
              <meta property="og:site_name" content="iPOS" />
              {!!description && <meta property="og:description" content={description} />}
              <meta property="og:image" content={`${process.env.IPOS_ROOT_URL}${image ? encodeImage(image) : '/ipos-banner.jpg'}`} />
              {/* TODO: per page? */}

              {/* <!-- Twitter --> */}
              <meta property="twitter:card" content="summary" />
              {/* <meta property="twitter:url" content={`${location.href}`} /> */}
              {/* TODO: page url */}
              <meta property="twitter:title" content={t} />
              <meta name="twitter:site" content="@iPOS_SA" />
              {!!description && <meta property="twitter:description" content={description} />}
              <meta property="twitter:image" content={`${process.env.IPOS_ROOT_URL}${image ? encodeImage(image) : '/ipos-banner.jpg'}`} />
              {/* TODO: per page? */}
            </Helmet>
          );
        }}
      </Location>
    </>
  );
};

export default MetaPage;
