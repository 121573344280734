/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors, dimensions, mixins, respondFrom, breakpoints, css } from '@styles';
import { Formik, FormikActions, Form } from 'formik';
import { InterCarsForm } from '@content/types/intercars';
import { DemoFormInput } from '@components/demo/DemoFormInput';
import { DemoFormCheckbox } from '@components/demo/DemoFormCheckbox';
import Button from '@components/common/Button';
import Collapsible from 'react-collapsible';
import Tooltip from '@components/common/Tooltip';
import Modal from '@components/common/Modal';
import LoaderCover from '@components/common/LoaderCover';
import * as Yup from 'yup';
import * as utils from '@utils';

const InterCarsFormWrapper = styled.div`
  ${respondFrom(
    breakpoints.lg,
    css`
      padding-right: 30px;
    `
  )}
`;

const ButtonWrapper = styled.div`
  display: inline-block;
`;

const AccordionWrapper = styled.div<AccordionWrapperProps>`
  margin-top: -25px;
  margin-bottom: 30px;
  margin-left: 30px;
  font-size: ${dimensions.fontSize.smaller}px;

  .Collapsible {
    &__trigger {
      position: relative;
      color: ${colors.green.dark};
      cursor: pointer;
      padding-right: 20px;

      &::before {
      content: '${props => props.title1}';
      }

      &::after {
        content: '';
        position: absolute;
        top: -3px;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 7px;
        height: 7px;
        border: 2px solid ${colors.green.dark};
        border-left: 0;
        border-top: 0;
        transform: rotate(45deg);
        transform-origin: center;
        ${mixins.transitionDefault};
      }

      &.is-open {
        &::before {
          content: '${props => props.title2}';
        }

        &::after {
          transform: translate(0, 3px) rotate(225deg);
        }
      }
    }
  }
`;

interface MyFormValues {
  name: string;
  email: string;
  phone: string;
  accept: boolean;
}

interface InterCarsFormProps {
  form: InterCarsForm;
}

interface AccordionWrapperProps {
  title1: string;
  title2: string;
}

class InterCarsFormComponent extends Component<InterCarsFormProps> {
  state = {
    accept: false,
    error: false,
    success: false,
    sending: false,
  };

  onChangeCheckbox = value => this.setState({ accept: !value });

  render() {
    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .matches(/^[0-9a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ'-,:\.\s]{0,100}$/, {
          message: this.props.form.error1,
        })
        .required(this.props.form.error2),
      email: Yup.string()
        .email(this.props.form.error3)
        .required(this.props.form.error4),
      phone: Yup.string()
        .matches(/^[0-9]{9,}$/, { message: this.props.form.error5 })
        .required(this.props.form.error6),
    });

    return (
      <InterCarsFormWrapper>
        {utils.SafeHtml(this.props.form.title)}

        <Formik
          initialValues={{ name: '', email: '', phone: '', accept: false }}
          validationSchema={validationSchema}
          onSubmit={(values: MyFormValues, actions: FormikActions<MyFormValues>) => {
            if (this.state.sending) return;

            this.setState({ error: false, success: false, sending: true }, () => {
              fetch('/cms/api/contact/form1', {
                method: 'POST',
                body: JSON.stringify({
                  name: values.name,
                  email: values.email,
                  phone: values.phone,
                  agreement: values.accept,
                }),
                headers: {
                  'Content-Type': 'application/json',
                },
              }).then(response => {
                if (response.status === 204) {
                  this.setState({ success: true, sending: false }, () => {
                    actions.resetForm();
                  });
                } else {
                  this.setState({ error: true, sending: false });
                }
              });
            });
            actions.setSubmitting(false);
          }}
          render={() => (
            <Form autoComplete="on">
              <DemoFormInput
                name="name"
                labelText={this.props.form.label1}
                type="text"
                maxlength={100}
              />
              <DemoFormInput
                name="email"
                labelText={this.props.form.label2}
                type="email"
                maxlength={100}
              />
              <DemoFormInput
                name="phone"
                labelText={this.props.form.label3}
                type="phone"
                maxlength={9}
              />
              <DemoFormCheckbox
                name="accept"
                labelText={this.props.form.checkbox}
                onChangeCheckbox={this.onChangeCheckbox}
              />

              <AccordionWrapper
                title1={this.props.form.accTitle1}
                title2={this.props.form.accTitle2}
              >
                <Collapsible trigger="">{utils.SafeHtml(this.props.form.accContent)}</Collapsible>
              </AccordionWrapper>

              <ButtonWrapper data-tip="React-tooltip" data-for="interCarsButton">
                <Button
                  icon="arrow-right"
                  type="submit"
                  disabled={!this.state.accept ? true : false}
                >
                  Wyślij
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        />
        <Modal
          isOpened={this.state.error || this.state.success}
          content={this.state.success ? this.props.form.modal1 : this.props.form.modal2}
        />
        <Tooltip
          className={this.state.accept ? 'is-hidden' : ''}
          text={this.props.form.tooltip}
          theme="light"
          id="interCarsButton"
        />
        <LoaderCover active={this.state.sending} />
      </InterCarsFormWrapper>
    );
  }
}

export default InterCarsFormComponent;
