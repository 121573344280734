import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions } from '@styles';
import { Field, FieldProps } from 'formik';

import errorIcon from '@assets/svg/error-icon.svg';

const Input = styled.label`
  display: block;
  position: relative;
  margin: 24px 0;

  span {
    display: block;
    color: ${colors.text.default};
    font-weight: 700;
    font-size: ${dimensions.fontSize.small}px;
    margin-bottom: 10px;
  }

  input {
    display: block;
    border: 1px solid ${colors.gray};
    background-color: ${colors.white};
    font-size: ${dimensions.fontSize.regular}px;
    font-weight: 300;
    color: ${colors.text.default};
    outline: none;
    padding: 16px 14px 15px;
    width: 100%;

    &::placeholder {
      color: ${colors.gray};
    }

    &:focus {
      border-color: ${colors.text.default};
    }

    &.isError {
      border-color: ${colors.error};
      padding-right: 35px;
    }
  }
`;

const Error = styled.div`
  color: ${colors.error};
  font-size: ${dimensions.fontSize.smaller}px;
  font-weight: 300;
  margin-top: 10px;
`;

const ErrorIcon = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  margin: auto;
`;

interface DemoFormInputProps {
  name: string;
  labelText: string;
  type?: string;
  autocomplete?: string;
  maxlength: number;
}

interface MyFormValues {
  email: string;
  email2: string;
}

export const DemoFormInput = ({
  name,
  labelText,
  type,
  autocomplete,
  maxlength,
}: DemoFormInputProps) => (
  <Field
    name={name}
    render={({ field, form }: FieldProps<MyFormValues>) => (
      <Input>
        <span>{labelText}</span>
        <input
          type={type}
          {...field}
          className={form.touched[name] && form.errors[name] ? 'isError' : ''}
          autoComplete={autocomplete}
          maxLength={maxlength}
        />

        {form.touched[name] && form.errors[name] && (
          <>
            <ErrorIcon src={errorIcon} />
            <Error>{form.errors[name]}</Error>
          </>
        )}
      </Input>
    )}
  />
);
