import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@styles';
import { Stylable } from '@content/types/general';

const Loader = styled.div`
  display: inline-block;
  width: 64px;
  height: 64px;

  &::after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid ${colors.green.dark};
    border-color: ${colors.green.dark} transparent ${colors.green.dark} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface Props extends Stylable {}

export default (props: Props) => <Loader className={props.className || ''} />;
