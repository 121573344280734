import get from 'lodash.get';
import { FileImageSharp } from '@content/types/api';

export const intercarsPageTransformers = page => {
  const intercarsLanguages = {};

  page.allSiteSettingEntityIntercars.edges.forEach(edge => {
    const data = edge.node;

    intercarsLanguages[data.langcode] = {
      seo: {
        title: get(data, 'field_meta_title', ''),
        description: get(data, 'field_meta_description', ''),
      },
      title: get(data, 'field_hero_headline.value', ''),
      image: get(data, 'relationships.field_image_1.localFile', null) as FileImageSharp,
      form: {
        title: get(data, 'field_info_text.value', ''),
        label1: get(data, 'field_teksty_formularza[0]', ''),
        label2: get(data, 'field_teksty_formularza[1]', ''),
        label3: get(data, 'field_teksty_formularza[2]', ''),
        error1: get(data, 'field_teksty_formularza[3]', ''),
        error2: get(data, 'field_teksty_formularza[4]', ''),
        error3: get(data, 'field_teksty_formularza[5]', ''),
        error4: get(data, 'field_teksty_formularza[6]', ''),
        error5: get(data, 'field_teksty_formularza[7]', ''),
        error6: get(data, 'field_teksty_formularza[8]', ''),
        checkbox: get(data, 'field_text_1.value', ''),
        accTitle1: get(data, 'field_agreement_1.value', ''),
        accTitle2: get(data, 'field_agreement_2', ''),
        accContent: get(data, 'field_agreement_3.value', ''),
        button: get(data, 'field_teksty_formularza[9]', ''),
        modal1: get(data, 'field_teksty_formularza[10]', ''),
        modal2: get(data, 'field_teksty_formularza[11]', ''),
        tooltip: get(data, 'field_teksty_formularza[12]', ''),
      },
    };
  });

  return intercarsLanguages;
};
