import { graphql, useStaticQuery } from 'gatsby';
import * as intercarsPageTransformers from '@content/transformers/IntercarsTransformers';
import { IntercarsPageProps } from '@content/types/intercars';

const IntercarsQuery = (): IntercarsPageProps => {
  const intercarsData = useStaticQuery(graphql`
    query {
      allSiteSettingEntityIntercars {
        edges {
          node {
            langcode
            field_agreement_1 {
              value
            }
            field_agreement_2
            field_agreement_3 {
              value
            }
            field_hero_headline {
              value
            }
            field_image_1 {
              alt
            }
            field_info_text {
              value
            }
            field_meta_description
            field_meta_title
            field_teksty_formularza
            field_text_1 {
              value
            }
            relationships {
              field_image_1 {
                localFile {
                  childImageSharp {
                    fixed(width: 600, quality: 90, fit: CONTAIN) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return intercarsPageTransformers.intercarsPageTransformers(intercarsData);
};

export default IntercarsQuery;
