import * as React from 'react';
import styled from '@emotion/styled';

const StyledPage = styled.div<StyledPageProps>`
  display: block;
  flex: 1;
  position: relative;
  margin-bottom: ${props => (props.noMarginBottom ? '0' : '3rem')};

  /* tmp, until we style other pages with h1 only */
  > div > h1 {
    margin-top: 2rem;
  }
`;

interface PageProps {
  className?: string;
  noMarginBottom?: boolean;
}

interface StyledPageProps {
  noMarginBottom?: boolean;
}

const Page: React.FC<PageProps> = ({ children, className, noMarginBottom }) => (
  <StyledPage noMarginBottom={noMarginBottom} className={className}>
    {children}
  </StyledPage>
);

export default Page;
