import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import Image, { GatsbyImageProps } from 'gatsby-image';

const InterCarsImageWrapper = styled.div`
  margin-top: 30px;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding-left: 30px;
      margin-top: 50px;
    `
  )}
`;

const StyledImage = styled(Image)`
  width: 100% !important;
  height: 0 !important;
  padding-bottom: 80%;
`;

interface InterCarsImageProps {
  image: GatsbyImageProps;
}

const InterCarsImage = ({ image }: InterCarsImageProps) => (
  <InterCarsImageWrapper>
    <StyledImage {...image} imgStyle={{ objectFit: 'contain' }} />
  </InterCarsImageWrapper>
);

export default InterCarsImage;
