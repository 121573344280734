import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import * as utils from '@utils';

const InterCarsTitleWrapper = styled.div`
  margin: 50px 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      text-align: center;
    `
  )}
`;

interface IntercarsTitleProps {
  title: string;
}

const InterCarTitle = ({ title }: IntercarsTitleProps) => (
  <InterCarsTitleWrapper>{utils.SafeHtml(title)}</InterCarsTitleWrapper>
);

export default InterCarTitle;
