import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions } from '@styles';
import { Field, FieldProps } from 'formik';
import * as utils from '@utils';

const Checkbox = styled.label`
  position: relative;
  display: block;
  margin-bottom: 30px;

  input {
    display: none;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid ${colors.gray};
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 7px;
    width: 7px;
    height: 12px;
    border: 1px solid ${colors.black};
    border-left: 0;
    border-top: 0;
    transform: rotate(45deg);
    opacity: 0;
    cursor: pointer;
  }

  span,
  > div {
    padding-left: 30px;
    vertical-align: middle;
    font-size: ${dimensions.fontSize.smaller}px;
    font-weight: 300;
    display: inline-block;
    cursor: pointer;
  }

  &.isChecked {
    &::before {
      border-color: ${colors.text.default};
    }

    &::after {
      opacity: 1;
    }
  }
`;

interface DemoFormCheckboxProps {
  name: string;
  labelText: string;
  onChangeCheckbox: Function;
  checked?: boolean;
  fromState?: boolean;
}

interface MyFormValues {
  email: string;
  email2: string;
  accept: boolean;
}

export const DemoFormCheckbox = ({
  name,
  labelText,
  onChangeCheckbox,
  checked,
  fromState,
}: DemoFormCheckboxProps) => (
  <Field
    name={name}
    render={({ field, form }: FieldProps<MyFormValues>) => (
      <>
        {fromState ? (
          <Checkbox className={checked ? 'isChecked' : ''}>
            <input type="checkbox" {...field} onClick={() => onChangeCheckbox(field.value)} />
            {utils.SafeHtml(labelText)}
          </Checkbox>
        ) : (
          <Checkbox className={field.value ? 'isChecked' : ''}>
            <input type="checkbox" {...field} onClick={() => onChangeCheckbox(field.value)} />
            {utils.SafeHtml(labelText)}
          </Checkbox>
        )}
      </>
    )}
  />
);
