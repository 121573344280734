import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions, css } from '@styles';
import ReactTooltip from 'react-tooltip';

const Tooltip = styled(ReactTooltip)`
  max-width: 200px;
  padding: 10px 15px;
  border-radius: 0;
  text-align: center;

  &.show {
    opacity: 1;
  }

  div {
    margin: 0;
    color: ${colors.text.default};
    font-size: ${dimensions.fontSize.smaller}px;

    p {
      margin-bottom: 10px;
    }
  }

  ul {
      text-align: left;
  }

  p, ul {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.place-top {
    ${props => !props.border && css`
      &::after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    `}
  }

  &.type-dark {
    background-color: ${colors.text.default};

    div {
      color: ${colors.white};
    }
  }

  &.type-light {
    border: 1px solid ${colors.text.default};
  }

  &.is-hidden {
    display: none;
  }

  &.align-left {
    text-align: left;
  }

  &.width--medium {
    max-width: 300px;
  }

  &.width--large {
    max-width: 500px;
  }
`;

interface Tooltip {
  text?: string | React.ReactElement;
  theme: string;
  id: string;
  className?: string;
  alignLeft?: boolean;
  width?: '' | 'medium' | 'large';
  clickable?: boolean;
  effect?: string;
  border?: boolean;
}

export default (props: Tooltip) => (
  <Tooltip
    place="top"
    type={props.theme}
    effect="float"
    id={props.id}
    // event="mouseover mouseenter"
    // eventOff="mouseleave mouseout scroll mousewheel blur"
    className={`
      ${props.className ? props.className : ''}
      ${props.alignLeft ? 'align-left' : ''}
      ${props.width ? `width--${props.width}` : ''}
    `}
    {...props}
  >
    {
      props.text &&
      <div>{props.text}</div>
    }
  </Tooltip>
);
