import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import { IntercarsPageProps } from '@content/types/intercars';

import InterCarsForm from '@components/interCars/InterCarsForm';
import InterCarsImage from '@components/interCars/InterCarsImage';

interface InterCarsGridProps {
  data: IntercarsPageProps;
}

const InterCarsGridWrapper = styled.div`
  display: flex;
  flex-flow: column;

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-flow: row;

      > div {
        width: 50%;
      }
    `
  )}
`;

const InterCarsGrid = (props: InterCarsGridProps) => {
  return (
    <InterCarsGridWrapper>
      <InterCarsForm form={props.data.form} />
      {props.data.image && <InterCarsImage image={props.data.image.childImageSharp} />}
    </InterCarsGridWrapper>
  );
};

export default InterCarsGrid;
